import { createUseStyles } from "react-jss";

import { Theme } from "src/styles/theme";

export const useExternalLinkStyles = createUseStyles((theme: Theme) => ({
  link: {
    "&:hover": {
      color: theme.palette.blue,
    },
    color: theme.palette.pink,
    transition: theme.hoverTransition,
  },
}));
