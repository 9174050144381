export const theme = {
  breakpoints: {
    upSm: "@media (min-width: 480px)",
  },
  hoverTransition: "all 150ms",
  maxWidth: 644,
  palette: {
    black: "#0a0b0d",
    blue: "#3b00bc",
    error: {
      light: "rgba(232,67,12,0.1)",
      main: "#e8430d",
    },
    grey: {
      dark: "#0d0d0f",
      light: "#0d0d0f",
      main: "rgba(245, 165, 73, 1)",
      veryDark: "#363740",
    },
    pink: "rgba(245, 165, 73, 1)",
    primary: {
      dark: "rgb(30 33 40 / 1)",
      main: "rgb(30 33 40 / 1)",
    },
    success: {
      light: "rgba(0,255,0,0.1)",
      main: "#1ccc8d",
    },
    transparency: "rgba(8,17,50,0.5)",
    warning: {
      light: "rgba(225,126,38,0.1)",
      main: "#e17e26",
    },
    white: "#ffffff",
  },
  spacing: (value: number): number => value * 8,
};

export type Theme = typeof theme;
